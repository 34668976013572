import React, { useEffect, useState } from 'react'
import logoSC from "../Imagenes/SC2024.png"
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: "90%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10
    },
    logo: {
        width: "500px",
        alignItems: "center",
        margin: "auto",
        paddingTop: "80px",
        "@media (max-width: 1280px)": {
            width: "80%",
            paddingTop: "50px",

        }
    },


    rowRanking: {
        color: "white",
        textAlign: "center",
        width: "60vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "@media (max-width: 1280px)": {
            width: "100vw",

        }
    },

    nombreCompetidor: {
        color: "white",
        textAlign: "center",
        fontSize: 50,
        "@media (max-width:1280px)":
        { fontSize: 30  }
    },
    nombreLiga: {
        color: "red",
        textAlign: "center",
        marginTop: "70px",
        fontSize: 25,
        "@media (max-width: 1280px)": { fontSize: 50 }
    },
    dni: {
        color: "white",
        textAlign: "center",
        marginTop: "10px",
        fontSize: "20px",
        "@media (max-width: 1280px)": { fontSize: 20 }
    },
    puntos:{
        color: "white", 
        textAlign: "center", 
        marginTop: "20px",
        fontSize: 20,
        "@media (max-width: 1280px)": { fontSize: 25 }
    },
    nombreDatos: {
        color: "white",
        textAlign: "center",
        marginTop: "20px",
        fontSize: "20px",
        "@media (max-width: 1280px)": { fontSize: 20 }
    },
    puesto: {
        color: "white",
        textAlign: "center",
        marginTop: "20px",
        fontSize: "20px",
        "@media (max-width: 1280px)": { fontSize: 40 }
    },
    rankingGeneral: {
        alignContent: "center",
        fontSize:30,
        color: "white",
        textAlign: "center",
        marginTop: "20px",
        "@media (max-width: 1280px)":
            { fontSize: 13 }
    },
})





function Ranking() {

    const classes = Styles()
    const { dni } = useParams();
    const [datosRanking, setDatosRanking] = useState([]);
    const [competidor, setCompetidor] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mensaje, setMensaje] = useState("Falta Ingresar un DNI");
    const [mensaje2, setMensaje2] = useState("error de sistema");

    useEffect(() => {
        const fetchDatosFull = async () => {
            try {
                setLoading(true)
                let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/Ranking/${dni}`,
                    {
                        method: "GET",
                        headers: { 'Content-type': 'application/json' },
                    })
                let response = await res.json()
                if (response.code === 200) {

                    setDatosRanking(response.ranking)
                    setCompetidor(response.competidor)
                    setLoading(false)

                } else {

                    setMensaje(response.mensaje)
                    setMensaje2(response.mensaje2)

                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchDatosFull();
    }, [dni])

    return (
        <div style={{ backgroundColor: "black", width: "100%", minHeight: "200vh" }}  >
            <div className={classes.logo} >
                <img src={logoSC} alt='' style={{ width:"100%" }} />
            </div>
            {loading === false ? <>
                <Typography variant='h2' className={classes.nombreCompetidor}> {competidor?.nombre?.toUpperCase()}</Typography>
                <Typography variant='h4' className={classes.dni}  > {"DNI: " + competidor?.dni}</Typography>

                <Typography variant='h4' className={classes.nombreDatos}  > {competidor?.categoria}</Typography>
                <Typography variant='h4' className={classes.nombreDatos}  > {competidor?.edad + " Años."}</Typography>
                <Typography variant='h2' className={classes.puesto}  > {competidor?.puesto > 0 ? "Puesto: " + competidor?.puesto : "Sin competencia registrada aun"}</Typography>
                <Typography variant='h2'className={classes.puntos}>  {competidor?.puntos ? competidor?.puntos + " puntos" : ""}</Typography>
                <Typography variant='h3' className={classes.nombreLiga}  > {datosRanking.liga_name}</Typography>
                <Typography variant='h4' style={{ color: "white", textAlign: "center", marginTop: "50px", marginBottom: "20px", width: "100%" }}>RANKING DE LIGA</Typography>


                {
                    datosRanking.data.map((x) => {
                        return (
                            <div style={{ color: "white", textAlign: "center", width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }} >
                                <div className={classes.rowRanking}>
                                    {x.dni === competidor.dni ? <>
                                        <Typography className={classes.rankingGeneral} style={{ backgroundColor: "red", width: "10%" }}>{x.puesto}</Typography>
                                        <Typography className={classes.rankingGeneral} style={{ backgroundColor: "red", width: "45%" }}>{x.nombre + " " + x.apellido}</Typography>
                                        <Typography className={classes.rankingGeneral} style={{ backgroundColor: "red", width: "10%" }}>{x.edad} años</Typography>
                                        <Typography className={classes.rankingGeneral} style={{ backgroundColor: "red", width: "20%" }}>{x.categoria}  </Typography>
                                        <Typography className={classes.rankingGeneral} style={{ backgroundColor: "red", width: "15%" }}>{x.puntos} pts.</Typography>
                                    </> : <>
                                        <Typography className={classes.rankingGeneral} style={{ width: "10%" }}  >{x.puesto}</Typography>
                                        <Typography className={classes.rankingGeneral} style={{ width: "45%" }}>{x.nombre + " " + x.apellido}</Typography>
                                        <Typography className={classes.rankingGeneral} style={{ width: "10%" }}>{x.edad} años</Typography>
                                        <Typography className={classes.rankingGeneral} style={{ width: "20%" }}>{x.categoria}  </Typography>
                                        <Typography className={classes.rankingGeneral} style={{ width: "15%" }}>{x.puntos} pts.</Typography>
                                    </>}
                                </div>
                            </div>
                        )
                    }
                    )}
            </>
                : <>
                    <div style={{ backgroundColor: "black", width: "100%", height: "90vh", paddingTop: "100px" }} >
                        <Typography variant='h2' style={{ color: "white", textAlign: "center" }}>{mensaje}</Typography>
                        <Typography variant='h5' style={{ color: "white", textAlign: "center" }}>{mensaje2}</Typography>
                    </div>
                </>
            }
        </div>
    );
}

export default Ranking;