import React from 'react'
 
 
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import Ranking from './page/ranking';

function App() {
  const { dni } = useParams();
   console.log("DNI: ", dni)
 
  return (
    <BrowserRouter>
      <Routes>

        <Route path="/:dni" element={<Ranking />}   />
        <Route path="/" element={<Ranking />}   />
        

    </Routes>
</BrowserRouter >
  );
}

export default App;
